<template>
	<div>
		<common-table
				:itemScope="['markerType', 'lshow', 'sqlid']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="标注"
				:listDeploy="listDeploy"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:markerType="{ item }">
				{{ markerTypeName(item.businesscode) }}
			</template>
			<template v-slot:sqlid="{ item }">
				<v-btn depressed color="primary" @click="configFacility(item)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					选择配套
				</v-btn>
			</template>

			<template v-slot:lshow="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lshow" hide-details dense/>
			</template>
		</common-table>
		<template v-if="dialog">
			<exhibition-data-box
					:dialog.sync="dialog"
					title="选择服务设施"
					:width="412"
					:contentHeight="650"
			>
				<cascade-checkbox
						:height="600"
						@closeDialog="dialog = false"
						:form="markerObject"
						:api="api"/>
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import cascadeCheckbox from "./cascadeCheckbox/cascadeCheckbox.vue";
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/map-marker.js';
import editorText from "@/components/editorText/editorText.vue";
import commonData from "@/api/common-data.js";
import material from "@/components/common-material/material.vue";
import markerFeature from "./marker-feature.vue";

export default {
	mixins: [commonCurd],
	components: {exhibitionDataBox, cascadeCheckbox},
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			typeCode: '',
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [
				{type: 'input', label: '输入标注代码/名称', property: 'searchkey'},
				{type: 'select', label: '标注类型', property: 'businesstype', items: []},
			],
			headers: [
				{text: '标注代码', value: 'code'},
				{text: '标注名称', value: 'description'},
				{text: '标注类型', value: 'markerType'},
				{text: '标注坐标', value: 'coordinate'},
				{text: '服务设施', value: 'sqlid'},
				{text: '状态', value: 'lshow'},
				{text: '排序号', value: 'seq'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "标注代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "标注名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'businesscode',
						connector: {
							props: {
								required: true,
								label: "标注类型",
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'groupid',
						connector: {
							props: {
								required: false,
								label: "标注分类",
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'coordinate',
						connector: {
							props: {
								required: true,
								label: "标注坐标",
								rules: [
									v => !!v || '纬度、经度不能为空，用英文逗号“,”分隔',
									v => (/^(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)$/.test(v)) || '坐标格式不正确，纬度、经度用英文逗号“,”分隔',
								],
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'clickurl',
					// 	connector: {
					// 		props: {
					// 			label: "跳转链接"
					// 		}
					// 	}
					// },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'address',
						connector: {
							props: {
								label: "街道地址"
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: singleTime,
					// 	property: 'openingtime',
					// 	connector: {
					// 		props: {
					// 			label: "开业日期"
					// 		}
					// 	}
					// },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'openinghours',
						connector: {
							props: {
								required: false,
								label: '营业/开放时段',
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'showprice',
						connector: {
							props: {
								label: "价格金额"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'tel',
						connector: {
							props: {
								label: "联系方式"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'clickurl',
						connector: {
							props: {
								label: "预订链接"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'vrviewurl',
						connector: {
							props: {
								label: "VR全景链接"
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: "VTextField",
					// 	property: 'seq',
					// 	connector: {
					// 		props: {
					// 			label: "排列序号"
					// 		}
					// 	}
					// },
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lshow',
						connector: {
							props: {
								required: true,
								label: "状态",
								inset: true
							}
						}
					},
					{
						cols: 12,
						subassembly: material,
						property: 'slidepics',
						connector: {
							props: {
								title: '应用端轮播图',
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								valueImage: ''
							}
						}
					},
					{
						cols: 12,
						subassembly: material,
						property: 'audiourl',
						connector: {
							props: {
								subTitle: '大小不能超过20MB，格式仅限于flac、mp3、wav格式',
								title: '上传音频',
								limit: 1,
								remark: 3,
								showAddIcon: false
							},
						}
					},
					{
						cols: 12,
						subassembly: material,
						property: 'videourl',
						connector: {
							props: {
								title: "视频（750*375）",
								limit: 1,
								remark: 2,
								showAddIcon: false,
								subTitle: '大小不能超过50MB，格式仅限于MP4、WebM、Ogg格式'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'div',
					// 	connector: {
					// 		class: 'form-row-title',
					// 		domProps: {
					// 			innerHTML: '标注简介'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: 'VTextarea',
					// 	property: 'introduction',
					// 	connector: {
					// 		props: {
					// 			solo: true,
					// 			rows: 2,
					// 			outlined: true,
					// 			flat: true
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: markerFeature,
						property: 'manage',
						connector: {
							props: {
								items: []
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '标注详情'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'richtext',
						connector: {
							props: {
								multi: 2
							}
						}
					},
				]
			},
			markerTypeList: [],
			dialog: false,
			markerObject: {}
		}
	},
	methods: {
		configFacility(item) {
			this.markerObject = item;
			this.dialog = true;
		},
		createdExtend() {
			this.fixedParams.parkid = this.$route.query.value;
			this.axios.post(
					commonData.select,
					commonData.getSelectList({
						keyname: "mapbusinesstype,mapbusclass",
						params: [{
							key: "mapbusinesstype",
							value: this.$route.query.value
						}]
					})
			).then((res) => {
				if (res?.data) {
					console.log(res.data)
					this.markerTypeList = res.data[0].values
					this.listDeploy.row[2].connector.props.items = this.markerTypeList
					this.listDeploy.row[3].connector.props.items = res.data[1].values
					this.leftConfig[1].items = this.markerTypeList
				}
			})
		},
		beforeSave(form) {
			form.parkid = this.$route.query.value;
		},
		beforeEdit(form) {
			if (form.slidepics) {
				this.listDeploy.row[8].connector.props.valueImage = form.slidepics
			}
			this.listDeploy.row[15].connector.props.items = form.manage ? form.manage : []
		},
		beforeOpenAddDialog(v) {
			if (v === 'add') {
				this.listDeploy.row[15].connector.props.items = []
			}
		},
		markerTypeName(code) {
			let marker = this.markerTypeList.find(item => item.code == code)
			return marker ? marker.desc : ''
		},
		async otherRightOperate(event) {
			if (event === "quickImport") {
				this.confirm
						.showConfirm(`确定快速导入当前酒店及会场数据吗`)
						.then(() => {
							this.axios.post(api.quickImport, {
								parkid: this.$route.query.value
							}).then((res) => {
								this.list()
							})
						})
						.catch(() => {
						});
			}
		},
	}
}
</script>

<style scoped lang="scss">

</style>
