<template>
	<div>
		<div style="overflow-y: auto; overflow-x: hidden;" :style="{height: height + 'px'}">
			<div class="d-flex flex-wrap">
				<template v-for="(item, index) in items">
					<v-col :cols="6" :key="index">
						<v-checkbox
								:label=item.description
								style="font-weight: bold; margin-top: 5px"
								hide-details
								:input-value="select.includes(item.code)"
								@change="change($event, item)"/>
					</v-col>
				</template>
			</div>
		</div>
		<div>
			<v-checkbox
					label="全选"
					hide-details
					style="display: inline-block"
					:indeterminate="indeterminate"
					:input-value="selectAll"
					@change="toggleSelectAll($event)"/>
			<div style="float: right; margin-top: 15px">
				<v-btn depressed outlined color="primary" @click="$emit('closeDialog')">取消</v-btn>
				<v-btn depressed color="primary" style="margin-left: 20px" @click="save">保存</v-btn>
			</div>
			<div style="clear: both;"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
			default: () => ({})
		},
		height: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			items: [],
			// 是否全部选中
			selectAll: false,
			select: [],
			indeterminate: false,
			object: {}
		}
	},
	methods: {
		change(v, item) {
			if (v && !this.select.includes(item.code)) {
				this.select.push(item.code)
			} else if (!v && this.select.includes(item.code)) {
				let res = []
				this.select.forEach(s => {
					if (item.code !== s) {
						res.push(s)
					}
				})
				this.select = res
			}
		},
		toggleSelectAll(e) {
			if (e) {
				this.items.forEach(a => {
					if (!this.select.includes(a)) {
						this.select.push(a.code)
					}
				})
			} else {
				this.select = []
			}
		},
		save() {
			this.object.packagecode = this.select.join(',')
			this.axios.post('/api/parkMap/save_parksite', this.object).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.snackbar.success('保存成功');
					this.$emit('closeDialog');
				}
			});
		}
	},
	watch: {
		form: {
			handler(v) {
				this.axios.post('/api/parkMap/parksitekit', {groupid: v.groupid, sqlid: v.sqlid}).then(res => {
					this.items = res.data.menus
					this.axios.post('/api/parkMap/load_parksite', {groupid: v.groupid, sqlid: v.sqlid}).then(res => {
						this.object = res.data
						this.select = this.object.packagecode.split(',')
						this.$forceUpdate()
					})
				})
			},
			immediate: true
		},
		select: {
			handler(v) {
				if (v.length === 0) {
					this.indeterminate = false
					this.selectAll = false
				} else if (v && v.length > 0) {
					if (this.items.length === this.select.length) {
						this.selectAll = true
						this.indeterminate = false
						this.items.forEach(item => {
							if (!this.select.includes(item.code)) {
								this.selectAll = false
								this.indeterminate = true
							}
						})
					} else {
						this.selectAll = false
						this.indeterminate = true
					}
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.mdi-minus-box::before {
		color: var(--v-primary-base);
	}

	.v-input--selection-controls__ripple {
		width: 0;
	}

	.v-label {
		color: #666666 !important;
	}
}

.col-6 {
	padding-top: 0;
	padding-bottom: 0;
}
</style>
