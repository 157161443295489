<template>
	<div class="mb-5">
		<div class="form-row-title">
			特色管理
		</div>
		<div class="">
			<div style="font-size: 13px; color: #999999; margin-bottom: 10px">
				<div>点击添加特色新增特色条，点击特色条左侧区域进行编辑标题，点击特色条右侧区域展开查看内容</div>
				<div>展开内容点击添加按钮新增上传区域，拖动选中的文件区域可对其排序</div>
				<div>图片建议：宽高比例16:9，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张</div>
			</div>
			<div v-for="(item, index) in list" :key="index" style="position: relative" class="mb-4">
				<div style="position: absolute; top: -10px; right: -5px; z-index: 999" @click="remove(index)">
					<v-icon style="cursor: pointer" size="32" class="iconfont">
						iconfont icon-guanbi-fill
					</v-icon>
				</div>
				<v-text-field
						solo
						hide-details
						v-model="item.title"
						@click:append="item.show = !item.show; $forceUpdate()"
						placeholder="特色标题"
						append-icon="mdi-chevron-down"
				></v-text-field>
				<div class="d-content" v-show="item.show">
					<material
							:key="index"
							:hasInput="true"
							label="图片备注"
							title=""
							:valueImage="item.valueImage"
							:valueText="item.valueText"
							@change1="changeEvt(item, $event)"/>
				</div>
			</div>
		</div>
		<v-btn color="primary" block class="mt-5" @click="add">
			<v-icon size="13" class="iconfont">iconfont icon-zengjia</v-icon>
			<span class="ml-1">新增特色</span>
		</v-btn>
	</div>
</template>

<script>
import material from "@/components/common-material/material.vue";

export default {
	name: "planning-box",
	components: {
		material
	},
	props: {
		items: Array
	},
	data() {
		return {
			select_list: [],
			list: []
		}
	},
	watch: {
		items: {
			handler(v) {
				if (v && v.length > 0) {
					this.list = v.map(item => {
						item.show = false
						let images = [], texts = []
						item.images.forEach(s => {
							images.push(s.imageurl)
							texts.push(s.name)
						})
						item.valueImage = images.join(',')
						item.valueText = texts.join(',')
						return item
					})
				}
			},
			immediate: true
		},
		list: {
			handler(v) {
				this.$emit('change', v)
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		remove(index) {
			this.confirm.showConfirm('确定删除特色吗').then(() => {
				this.list.splice(index, 1)
			}).catch(() => {
			});
		},
		add() {
			this.list.push({title: '', images: [{imageurl: '', name: ''}], show: false})
		},
		changeEvt(item, params) {
			let res = []
			params.forEach(item => {
				res.push({name: item.text, imageurl: item.url})
			})
			item.images = res
		}
	}
}
</script>

<style scoped lang="scss">
.d-title {
	height: 40px;
	background: #FFFFFF;
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #D8D8D8;
	display: flex;
	align-items: center;
	padding-left: 24px;
}

.d-content {
	background: #f5f5f5;
	padding: 16px;

	::v-deep {
		.v-input__slot {
			border: none;
		}
	}
}

::v-deep {
	.v-input__slot {
		box-shadow: none !important;
		border: 1px solid #e7e7e7;
		font-size: 14px;
		border-radius: 6px;
	}

	.v-select__slot {
		background: #FFFFFF;
		padding-left: 10px;
		font-size: 14px;
	}
}
</style>
